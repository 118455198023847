import React, { useState,useEffect } from 'react';
import {Table, Container, Button} from 'react-bootstrap';
import { GetBLOGCategory, DeleteBLOGCategory } from '../../../entities/action/action';
import classes from '../../WebsiteManagement/websiteManagement.module.css';

const  BlogCategoryList = (props)=>{

    useEffect(()=>{
        (async()=>{
            let arr = await GetBLOGCategory();
            if(arr?.success){
                updateDataSet(arr?.blogCategories);
            }
        })()
    },[])

    const [dataSet,updateDataSet] = useState([]);
    const handleDelete = async(id)=>{
        const res = await DeleteBLOGCategory(id);
        if(res?.success){
            alert("Successfully Deleted");
            window.location.reload()
        }
    }
    return(
        <Container fluid style={{padding:'0'}}>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Blog {`${props.list}`}</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        dataSet?.length>0?dataSet?.map((data,key)=>
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td>{data.categoryName}</td>
                                <td className={classes.CenterRow}>
                                    <Button style={{margin:'auto 1%'}}  onClick={()=>handleDelete(data?.id)} variant="danger">Delete</Button>
                                </td>
                            </tr>
                        ):null
                    }
                </tbody>
            </Table>
        </Container>
    )
}
export default  BlogCategoryList;