import React, { useState, useEffect } from 'react';
import { Button,Form,Row,Col } from 'react-bootstrap';
import { AddBLOGSubCategory, GetBLOGCategory } from '../../../entities/action/action';
import BlogCategorySubcategoryList from './listForBlogSubCategory';

const AddBlogSubCategoryForm = (props)=>{
    const [category,SetCategory] = useState('');
    const [subCategory,SetSubCategory] = useState('');
    const [dataSetCategories,setDataSetCategories] = useState([]);

    useEffect(()=>{
        (async()=>{
            let arr = await GetBLOGCategory();
            if(arr?.success){
                setDataSetCategories(arr?.blogCategories);
            }
        })()
    },[])

    const handleSubmit = async()=>{
        const newData = {
            "subCategoryName": subCategory,
            "categoryId": category,
          }
        let response;
        if(props?.categoryType === "SUB CATEGORY"){
            response = await AddBLOGSubCategory(newData);
            console.log(response)
            if(response==="success"){
                alert("Successfully Added Category");
                window.location.reload();
            }
        } 
         
    }
    return(
        <div> 
            <Form.Group controlId="formBasicPromo" as={Row}>
                <Form.Label column sm="3">Blog Category:</Form.Label>
                <Col sm="9"> 
                    <Form.Control 
                        as="select" 
                        placeholder="Status" 
                        name="Priority"
                        value={category}
                        onChange={(e)=>SetCategory(e?.target?.value)}
                    >
                        <option value="">--Select--</option>
                        {
                            dataSetCategories?.map(category=>
                                <option value={category?.id}>{category?.categoryName}</option>
                            )
                        }
                    </Form.Control>
                </Col>
            </Form.Group>
            <Form.Group controlId="formBasicPromo" as={Row}>
                <Form.Label column sm="3">Blog {`${props.categoryType}`}:</Form.Label>
                <Col sm="9"> 
                    <Form.Control
                        type="text" 
                        placeholder={`Enter Blog ${props.categoryType}`} 
                        name="category"
                        onChange={(e)=>SetSubCategory(e.target.value)}
                        value={subCategory}
                        required/>
                </Col>
            </Form.Group>
            <center>
                <Button onClick={handleSubmit} style={{margin:'2%'}}>Save Blog {`${props.categoryType}`}</Button>
            </center>
            <BlogCategorySubcategoryList list={`${props.categoryType}`}/>
        </div>
    )
}
export default AddBlogSubCategoryForm;